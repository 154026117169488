import { View } from '@react-pdf/renderer';
import Question from '@rsa-digital/evo-shared-components/components/pdf/components/Question';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Heading2 } from '../typography';

type PdfDeclaration = {
  bodyText: string;
  questionText: string;
  questionAnswer: string;
  disclaimerText?: string;
};

export type DeclarationSectionProps = {
  theme: DefaultTheme;
  heading: string;
  summary: string;
  declarations: PdfDeclaration[];
};

const DeclarationSection: React.FC<DeclarationSectionProps> = ({
  heading,
  declarations,
  summary,
  theme,
}) => (
  <>
    <Section>
      <Heading2>{heading}</Heading2>
      <SimpleDivider theme={theme} />
      <View style={{ marginTop: 24 }} />
      <RichText html={summary} theme={theme} />
      <View style={{ marginBottom: 16 }} />
      {declarations.map((declaration) => (
        <Fragment key={declaration.bodyText}>
          <View
            style={{
              marginBottom: 24,
            }}
            wrap={false}>
            <RichText html={declaration.bodyText} theme={theme} />
            <SimpleDivider theme={theme} noMargins />
            <Question
              question={declaration.questionText}
              answer={declaration.questionAnswer}
            />
            {declaration.disclaimerText !== undefined &&
              declaration.disclaimerText !== '' && (
                <>
                  <View style={{ marginTop: 16 }} />
                  <RichText html={declaration.disclaimerText} theme={theme} />
                </>
              )}
          </View>
        </Fragment>
      ))}
    </Section>
  </>
);

export default DeclarationSection;
