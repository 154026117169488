import { Text, View } from '@react-pdf/renderer';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import { EmphasisParagraphText } from '@rsa-digital/evo-shared-components/components/pdf/components/typography';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { Heading2 } from '../typography';

export type PricingOverviewSectionProps = {
  theme: DefaultTheme;
  paymentLabel: string;
  paymentSpecificDetails?: string;
  paymentGeneralDetails?: string;
  prices:
    | {
        total: number;
        perPet: number[];
      }
    | undefined;
  petInfos: QuoteResponsePetInfo[] | null;
};

const PricingOverviewSection: React.FC<PricingOverviewSectionProps> = ({
  theme,
  paymentLabel,
  paymentSpecificDetails,
  paymentGeneralDetails,
  prices,
  petInfos,
}) => (
  <>
    <Section>
      <Heading2>Payment Overview</Heading2>
      <SimpleDivider theme={theme} />
      <View
        style={{
          paddingVertical: 16,
        }}>
        <Text style={{ marginBottom: 16 }}>
          {paymentLabel}
          {prices && (
            <EmphasisParagraphText>
              : {formatInPoundsWithSign(prices.total)}
            </EmphasisParagraphText>
          )}
        </Text>
        {prices &&
          petInfos &&
          petInfos.map((pet, index) => (
            <Fragment key={pet.petName}>
              <Text style={{ marginBottom: 16 }}>
                {pet.petName}:{' '}
                <EmphasisParagraphText>
                  {formatInPoundsWithSign(prices.perPet[index])}
                </EmphasisParagraphText>
              </Text>
            </Fragment>
          ))}
        {paymentSpecificDetails && (
          <RichText theme={theme} html={paymentSpecificDetails} />
        )}
        {paymentGeneralDetails && <RichText theme={theme} html={paymentGeneralDetails} />}
      </View>
    </Section>
  </>
);

export default PricingOverviewSection;
