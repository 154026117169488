import { Text } from '@react-pdf/renderer';
import React from 'react';

// medium heading
export const Heading1: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Text
    style={{
      fontSize: 40,
      fontWeight: 'bold',
      lineHeight: 1.5,
    }}>
    {children}
  </Text>
);

// small heading
export const Heading2: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Text
    style={{
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
    }}>
    {children}
  </Text>
);
